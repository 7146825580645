import { SCREENING_API } from '@/constants'
import { CheckSanctionAddressesResult, MultisendRecipients } from '@/types/screening'


export type ValidateResponseCheckAddress = {
  isGoodAddress: boolean;
}

export type ValidateResponseCheckAddresses = {
  isGoodList: boolean;
  badList: Array<{ address: string; index: number }>;
}

export const checkIsSanctionAddress = async (address: string): Promise<boolean> => {
  try {
    const response = await fetch(
      `${SCREENING_API}/validate-address/?address=${address}`,
    )

    if (!response.ok) {
      throw new Error('Validate address response not ok')
    }

    const data: ValidateResponseCheckAddress = await response.json()

    return !data.isGoodAddress
  } catch {
    return true
  }
}

export const checkSanctionAddresses = async (originalList: [string, string][])
  : Promise<CheckSanctionAddressesResult> => {
  const addresses: string[] = []
  for (let i = 0; i < originalList.length; i++) {
    addresses.push(originalList[i][0])
  }

  try {
    const response = await fetch(`${SCREENING_API}/validate-addresses`, {
      method: 'post',
      body: JSON.stringify({ addresses }),
      headers: {
        'Content-type': 'application/json',
      },
    })

    if (!response.ok) {
      throw new Error('Validate addresses response not ok')
    }

    const data: ValidateResponseCheckAddresses = await response.json()

    if (data.isGoodList) {
      return { isAllAddresses: false, badAddresses: {} }
    }

    const badAddresses: MultisendRecipients = {}
    for (let i = 0; i < data.badList.length; i++) {
      const { address, index } = data.badList[i]
      badAddresses[address] = { addr: address, amount: originalList[index][1] }
    }

    return { isAllAddresses: Object.keys(badAddresses).length === originalList.length, badAddresses }
  } catch {
    const badAddresses: MultisendRecipients = {}
    for (let i = 0; i < originalList.length; i++) {
      const [address, amount] = originalList[i]
      badAddresses[address] = { addr: address, amount }
    }

    return { isAllAddresses: true, badAddresses }
  }
}
